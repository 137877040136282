<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <div class="search_center">
            <span
              >部门：
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </span>
          </div>
          <div class="search_center">
            <span
              >项目名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.projectName"
                :fetch-suggestions="projectQuerySearch"
                placeholder="请输入或选择项目名"
                @select="onProkectNameSelect"
              >
              </el-autocomplete>
            </span>
          </div>
          <div class="search_center">
            <span
              >项目状态：
              <el-select
                v-model="parameter.statusList"
                placeholder="请选择项目状态"
                multiple
                class="ipt_width"
              >
                <el-option
                  v-for="item in StautsType"
                  :label="item.dictName"
                  :value="item.dictVal"
                  :key="item.dictVal"
                ></el-option>
              </el-select>
            </span>
          </div>
          <!-- <div class="search_center">
            <span
              >项目类型：
              <el-select
                v-model="parameter.projectType"
                placeholder="请选择项目类型"
                clearable
                class="ipt_width">
                <el-option
                  v-for="item in ProjectType"
                  :label="item.dictName"
                  :value="item.dictVal"
                  :key="item.dictVal"></el-option>
              </el-select>
            </span>
          </div> -->

          <div class="search_center">
            <span
              >业主单位：
              <el-select
                v-model="parameter.customersUnit"
                placeholder="请选择业主单位"
                clearable
                filterable
                class="ipt_width"
              >
                <el-option
                  v-for="item in customersUnit"
                  :label="item"
                  :value="item"
                  :key="item"
                ></el-option>
              </el-select>
            </span>
          </div>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            row-key="id"
            lazy
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              fixed="left"
              prop="projectName"
              label="项目名称"
              :show-overflow-tooltip="false"
              min-width="320"
            ></el-table-column>

            <!-- <el-table-column prop="projectType" align="center" label="项目类型">
              <template slot-scope="scope">
                {{ scope.row.projectType | dict(dictData.projectType) }}
              </template>
            </el-table-column> -->
            <el-table-column align="center" width="180" prop="status" label="项目状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.projectStatus) }}
              </template>
            </el-table-column>

            <!-- <el-table-column
              align="center"
              prop="agreementName"
              label="合同名称"
            ></el-table-column> -->
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="customersUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              prop="actualContractAmount"
              label="合同实际金额"
            ></el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              prop="projectBudget"
              label="预估成本"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectPredictStartDate"
              width="130"
              sortable
              label="预计开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.projectPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="projectPredictEndDate"
              width="130"
              sortable
              label="预计结束时间"
            >
              <template slot-scope="scope">
                {{ scope.row.projectPredictEndDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="deptClassifyName" width="120" label="部门分类">
            </el-table-column>
            <el-table-column
              align="center"
              prop="deptClassifyManagerName"
              width="100"
              label="项目经理"
            >
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="70">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="edit(scope.row)"
                  >详情</el-button
                >
                <!-- <el-button
                  v-if="
                    scope.row.operatingButton.some((r) => {
                      return r == 'DELETE';
                    })
                  "
                  type="text"
                  size="small"
                  @click="del(scope)"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit
      :isShow.sync="showEditDialog"
      :StautsType="StautsType"
      :ProjectType="ProjectType"
      :options="options"
    ></Edit>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'

export default {
  components: {
    Edit: () => import('../Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
      },
      pageSize: 0,
      dictData: {
        projectType: [],
        projectStatus: [],
      },
      tableData: [],
      deptList: [],
      loading: false,
      options: {},
      showEditDialog: false,
      ProjectType: [],
      StautsType: [],
      customersUnit: [],
      projectList: [],
    }
  },
  watch: {},
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()
    // this.$api.dict
    //   .listSysDictData('PROJECT_TYPE', true)
    //   .then(res => {
    //     this.dictData.projectType = res.data
    //     // PROJECT_TYPE 项目类型
    //     this.ProjectType = initTreeData(res.data)
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    this.$api.dict
      .listSysDictData('PROJECT_STATUS', true)
      .then(res => {
        this.dictData.projectStatus = res.data
        // PROJECT_STATUS 项目状态
        this.StautsType = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.sysDept
      .getTreeDept()
      .then(res => {
        this.deptList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.projectName = e.value
      }
      this.$forceUpdate()
    },
    handleReset() {
      this.parameter.projectName = ''
      this.parameter.projectType = ''
      this.parameter.customersUnit = ''
      this.parameter.statusList = []
      this.parameter.deptId = ''
      this.getData() /** 加载 "付款申请审核" 数据 **/
    },
    getProject() {
      this.$api.project
        .listProject({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    sortChange(column) {
      this.tableData = []
      if (column) {
        const orderMapping = {
          projectPredictStartDate: 'project_Predict_Start_Date',
          projectPredictEndDate: 'project_Predict_End_Date',
          projectBudget: 'project_budget',
          actualContractAmount: 'actual_contract_amount',
        }

        const orderRuleMapping = {
          ascending: 'ASC',
          descending: 'DESC',
        }

        if (orderMapping[column.prop] && orderRuleMapping[column.order]) {
          this.parameter.order = orderMapping[column.prop]
          this.parameter.orderRule = orderRuleMapping[column.order]
        } else if (column.order == null) {
          this.parameter.order = null
          this.parameter.orderRule = null
        }
        this.$api.project.listProject(this.parameter).then(res => {
          const arr = res.data.records.map(r => ({
            ...r,
            hasChildren: r.parentId === 0 && r.isHaveChild === true,
            children: [],
          }))

          this.tableData = arr
        })
      }
    },
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    // add() {
    //   this.options = {};
    //   this.showEditDialog = true;
    // },
    edit(row) {
      row.ProjectDisable = 4
      row.AssignProjectManager = 'AssignProjectManager'
      row.projecNoIndex = 1
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true

      this.$api.project
        .listProject(this.parameter)
        .then(res => {
          this.loading = false
          var arr = []

          res.data.records.some(r => {
            arr.push(
              r.combination({
                hasChildren: r.parentId == 0 && r.isHaveChild == true,
                children: [],
              })
            )
          })

          this.tableData = arr
          this.tableData.forEach(v => {
            if (this.customersUnit.indexOf(v.customersUnit) == -1) {
              this.customersUnit.push(v.customersUnit)
            }
          })
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    load(tree, treeNode, resolve) {
      this.$api.project
        .listProjectNotPage({
          parentId: tree.id,
        })
        .then(res => {
          var arr = []
          res.data.some(r => {
            arr.push(
              r.combination({
                hasChildren: false,
              })
            )
          })
          resolve(arr)
        })
        .catch(err => {
          resolve([])
          console.log(err)
        })
    },
    // del(scope) {
    //   this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$api.project
    //         .del(scope.row.id)
    //         .then((res) => {
    //           this.$message.success("删除成功！");

    //           var hasLastPage =
    //             this.parameter.pageSize * this.parameter.pageNow >=
    //             this.parameter.total;
    //           var lastData = this.tableData.length < 2;
    //           if (lastData && hasLastPage && this.parameter.pageNow > 1) {
    //             this.parameter.pageNow--;
    //           }
    //           this.getData();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //       //用户取消
    //     });
    // },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
